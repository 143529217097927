

window.vr={
    toggle_menu(){
        document.body.classList.toggle('burger_close');
    },
    toggle_search(){
        document.body.classList.toggle('search_close');
    }
};
/**
 * jquery.ready
 */
function ready(callbackFunc) {
    if (document.readyState !== 'loading') {
        // Документ уже готов
        callbackFunc();
    } else if (document.addEventListener) {
        // Все современные браузеры для регистрации DOMContentLoaded
        document.addEventListener('DOMContentLoaded', callbackFunc);
    } else {
        // Старые IE
        document.attachEvent('onreadystatechange', function() {
            if (document.readyState === 'complete') {
                callbackFunc();
            }
        });
    }
}


function FadeSlider(elements,opt){
    opt = Object.assign({
        delay: 3000,
    },opt);
    document.querySelectorAll(elements).forEach(function(el){
        // document.querySelectorAll('.sl').forEach(function(el){
        let show_selector = el.getAttribute('show-selector')||'show';
        let hide_selector = el.getAttribute('hide-selector')||'hide';
        document.querySelectorAll('.sl>*').forEach(function(v, i){
            v.classList.add(i===0?show_selector:hide_selector)
        });
        setInterval(function(){
            let show_selector = el.getAttribute('show-selector')||'show';
            let hide_selector = el.getAttribute('hide-selector')||'hide';
            let slides = el.children;
            let current = Array.prototype.slice.call(slides).indexOf(el.querySelector(':scope>.'+show_selector));
            if(current===-1){current = 0;}
            let next = current+1;
            if(next>=slides.length){next = 0;}
            slides[current].classList.remove(show_selector);
            slides[current].classList.add(hide_selector);
            slides[next].classList.remove(hide_selector);
            slides[next].classList.add(show_selector);
        },el.getAttribute('delay')||opt.delay)
    })
}




ready(function() {
    FadeSlider('.sl');
});







function search(){
    console.log('form');
}







